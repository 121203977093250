import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
/** Import Vidday Libs */
import { formatBaseFile } from '../utils'
import { postMedia } from '../../../../../api/app/media'
import configClient from '../../../../../../client/config.client'

/* polyfills */
import 'core-js'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// Order matters: AbortController needs fetch which needs Promise (provided by core-js).
import 'md-gum-polyfill'
import ResizeObserver from 'resize-observer-polyfill'
if (window.ResizeObserver === undefined || window.ResizeObserver === null) {
	window.ResizeObserver = ResizeObserver
}

/** Import Uppy Libraries and React-specific components */

/** Import Custom Hooks */
import useUppy from '../hooks/useUppy'
import useContributorId from '../hooks/useContributorId'
import useGroupMediaUUID from '../hooks/useGroupMediaUUID'

/** Import Styles (including those related to each Plugins) */
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './uppy.overwrite.css'

const useUploaderURL = ({
	// The maximum amount of files that can be uploaded
	maxNumberOfFiles,
	allowedFileTypes,
	handleClose,
	event,
	onUpload,
}) => {
	const { id, uuid } = event

	const dispatch = useDispatch()
	const contributorId = useContributorId()
	const groupMediaUuid = useGroupMediaUUID()

	const uppy = useUppy(uuid, {
		id: 'upload-url',
		autoProceed: false,
		restrictions: {
			maxNumberOfFiles: maxNumberOfFiles,
			allowedFileTypes: allowedFileTypes,
		},
		meta: {
			eventId: id,
			eventUuid: uuid,
			uploadUuid: groupMediaUuid,
		},
	})

	/**
	 * Method to handle the Store Upload
	 * @param {*} file
	 */
	const handleStoreUpload = (file, response) => {
		let upload = formatBaseFile(file, event)
		/** Attach the contributor ID */
		upload.contributorId = contributorId
		/** Check if this is TUS upload */
		if (response.uploadURL) {
			const uploadId = response.uploadURL.split('/files/')[1]?.split('+')[0] // upload id is portion between "/files/" and "+"
			upload.uploadKey = `${configClient.uppy.tusPrefix}${uploadId}` // tus upload key
		}
		/** Post media */
		dispatch(postMedia(upload, false)) // new Media Model, add to reducer [uploadsCompleted]
	}

	useEffect(() => {
		if (uppy) {
			uppy.on('file-removed', (file, reason) => {
				if (reason == 'removed-by-user') {
					console.log('File :', file.id, ' removed by user.')
				}
			})

			uppy.on('upload', ({ id, fileIDs }) => {
				// data object consists of `id` with upload ID and `fileIDs` array
				// with file IDs in current upload
				uppy.log(`Starting upload ${id} for files ${fileIDs}`)

				// Callback event to let the rest of the app we're currently uploading
				onUpload && onUpload(true)
			})

			uppy.on('upload-success', (data, response) => {
				/* Detect 0 byte files */
				if (data.size > 0 || data.isRemote == true) {
					/* Detected non-zero byte file, proceed */
					handleStoreUpload(data, response) // Add file to database and begin processing

					uppy.removeFile(data.id)
					uppy.info(`${data.data.name || data.name} - uploaded!`, 'success', 6000)
				} else {
					/* Detected 0 byte file, reject */
					const error = new Error('Uploaded file is 0 bytes. Please retry.')
					// Emit error for this file
					uppy.emit('upload-error', data, error, response)
				}

				// Should we shut things down?
				let remainingFiles = uppy.getFiles()
				if (remainingFiles.length <= 0) {
					handleClose()
				}
			})

			uppy.on('error', (err) => {
				// When entire upload fails
				uppy.log('on ERROR : An error occured :', 'error')
				console.error(`on ERROR : An error occured : ${err.message}`)
			})

			uppy.on('upload-error', (file, error, response) => {
				// Fired each time a single upload has errored.
				console.error('Error with file : ', file.id)
				console.error('Error reason : ', error)
				console.error('Error Response from Companion : ', response)

				// Do other things here, notify user, highlight failed file in red, etc..
				//dispatch(addFlashMessage('error', `${file.data.name} - failed.`)) // notify the user this one is safe
			})
		}

		return () => uppy.close({ reason: 'unmount' })
	}, [uppy])

	return uppy
}

useUploaderURL.defaultProps = {
	maxNumberOfFiles: 200,
	allowedFileTypes: ['video/*', 'image/*'],
	handleClose: undefined,
	event: undefined,
	onUpload: undefined,
}

useUploaderURL.propTypes = {
	maxNumberOfFiles: PropTypes.number,
	handleClose: PropTypes.func.isRequired,
	onUpload: PropTypes.func,
	event: PropTypes.object.isRequired,
}

export default useUploaderURL
